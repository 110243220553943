<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="45%"
  >
    <div class="editTitle">基本信息</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="120px"
        :rules="rules"
        ref="editVersionForm"
      >
        <el-row >
          <el-col :span="12">
            <el-form-item label="设备编号：" prop="equipNo">
              <el-input
              :readonly="equipNoShow"
                v-model="editForm.equipNo"
                size="small"
              
                placeholder="请输入设备编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现场编号：" prop="actNo">
              <el-input
                v-model="editForm.actNo"
                size="small"
                placeholder="请输入现场编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属项目：" prop="projId">
              <el-select
                class="block-select"
                size="small"
                clearable
                filterable
                v-model="editForm.projId"
                placeholder="请选择所属项目"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属商户：" prop="busiId">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.busiId"
                placeholder="请选择所属商户"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备类型：" prop="equipType">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.equipType"
                placeholder="请选择设备类型"
              >
                <el-option
                  v-for="item in equipType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号：" prop="equipModel">
              <el-input
                v-model="editForm.equipModel"
                size="small"
                placeholder="请输入规格型号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="制造厂家：" prop="equipFactory">
              <el-input
                v-model="editForm.equipFactory"
                size="small"
                placeholder="请输入制造厂家"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检测单位：" prop="equipTestUnit">
              <el-input
                v-model="editForm.equipTestUnit"
                size="small"
                placeholder="请输入检测单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出厂编号：" prop="equipFactoryNo">
              <el-input
                v-model="editForm.equipFactoryNo"
                size="small"
                placeholder="请输入出厂编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出厂日期：" prop="equipDate">
              <el-date-picker
              class="date"
                v-model="editForm.equipDate"
                type="datetime"
                placeholder="选择出厂日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产权备案号：" prop="equipOwnership">
              <el-input
                v-model="editForm.equipOwnership"
                size="small"
                placeholder="请输入产权备案号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用状态：" prop="useState">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.useState"
                placeholder="请选择使用状态"
              >
                <el-option
                  v-for="item in useStateList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件：" prop="staffPic">
        <el-upload
            class="file-uploader"
            :action="`${uploadUrl}File/upload`"
            :data="{type:1}"
            :show-file-list="false"
            :on-success="uploadSuccess">
          <div class="upload-main">
                  <span class="upload-btn">
                    <i class="el-icon-upload"></i>上传附件
                  </span>
            仅支持上传图片、pdf格式
          </div>
          <div class="file-url-main">
            <div v-for="(url,index) in editForm.annexUrlList" class="file-url-item" :key="index">
             <div class="file-url">{{url.match('--')? url.split('--')[1]: url}}</div>
              <span class="file-url-btn el-icon-close" circle @click.stop="delImg(index)"></span>
            </div>
          </div>
        </el-upload>
      </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
  import {equipType,useState} from "../../config/dataStatus"
import basic from '../../api/modules/basic'

export default {
  name: "EditVersion",
  components: { CustomDialog },
  props: ["visible", "title",'item','equipNoShow'],
  data() {
    return {
      editForm: {},
      rules: {
        equipNo: [{ required: true, message: "请输入设备编号", trigger: "blur" }],
        actNo: [{ required: true, message: "请输入现场编号", trigger: "blur" }],
        projId: [
          { required: true, message: "请选择项目", trigger: "change" },
        ],
        equipModel: [{ required: true, message: "请输入规格型号", trigger: "blur" }],
        busiId: [{ required: true, message: "请选择所属商户", trigger: "change" }],
        equipType: [{ required: true, message: "请选择设备类型", trigger: "change" }],
        equipDate:[{ required: true, message: "请选择出厂日期", trigger: "change" }],
        useState:[{ required: true, message: "请选择使用状态", trigger: "change" }],
        equipFactory: [{ required: true, message: "请输入制造厂家", trigger: "blur" }],
        equipTestUnit: [{ required: true, message: "请输入检测单位", trigger: "blur" }],
        equipFactoryNo: [{ required: true, message: "请输入出厂编号", trigger: "blur" }],
        equipOwnership: [{ required: true, message: "请输入产权备案号", trigger: "blur" }],
      },
      uploadUrl: config.uploadUrl,
      // equipType: [],
      equipType: equipType,
      useStateList:useState,
      projectList:[],
      businessList:[]
    };
  },
  watch: {
    item(val) {
      console.log('val',val);
      
      if (val.equipId) {
        this.editForm = val;
        this.editForm.equipType =Number(val.equipType)
      }else{
        this.editForm={
          equipNo:'',
          actNo:'',
          projId:'',
          busiId:'',
          equipType:'',
          equipModel:'',
          equipDate:'',
          useState:'',
          annexUrlList:[]

        }
      }
    },
  },
  mounted(){
    this.getProjectList()
    this.getBasebusiness()
  },  
  methods: {
    colseDialog() {
      
      this.$emit("close");
    },
    getProjectList(){
      basic.GetProjectList().then(res=>{
        console.log('项目列表',res);
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
    getBasebusiness(){
      basic.getBasebusinessList().then(res=>{
        console.log('s商户列表',res);
        res.data.map(item=>{
          this.businessList.push({
            label:item.busiName,
            value:item.busiId
          })
        })
        
      })
    },
    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          if(this.editForm.equipId){
            basic.editBaseEquip(this.editForm).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
            this.resetForm('editVersionForm')
          });
          }else{
            basic.addBaseEquip(this.editForm).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$emit("done");
            this.resetForm('editVersionForm')
          });
          }
    
        }
      });
    },
    uploadSuccess(val){
      if(val.code == 200){
        // 附件信息，未完待续
        this.editForm.annexUrlList.push(val.data)
        this.$forceUpdate()
      }
    },
    delImg(index){
      this.editForm.annexUrlList.splice(index,1)
      this.$forceUpdate()
    },
    resetForm(formName) {
        this.$refs[formName].resetFields();
      },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid  #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
