<template>
    <div class="pageMain">
      <search-head :model="searchForm" @search="search" @reset="reset">
        <el-form :inline="true" :model="searchForm">
            <el-form-item label="设备编号">
          <el-input v-model="searchForm.equipNo" size="small" placeholder="设备编号"></el-input>
        </el-form-item>
        <el-form-item label="所属项目">
          <el-select v-model="searchForm.projId" size="small" placeholder="请选择" filterable>
            <el-option
      v-for="item in projectList"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用状态">
          <el-select v-model="searchForm.useState" size="small" placeholder="请选择">
            <el-option
      v-for="item in useStateList"
      :key="item.value"
      :label="item.name"
      :value="item.value">
    </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
          height="55vh"
          :total="total"
          title="设备管理">
        <template slot="btns">
          <el-button type="success" size="small" @click="addFrom" icon="el-icon-plus" class="addBtn">新增</el-button>
        </template>
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
        <el-table-column
            prop="useState"
            label="使用状态"
            align="center"
          >
          <template slot-scope="scope">
          <el-tag v-if="scope.row.useState == 0" type="warning">闲置 </el-tag>
          <el-tag v-if="scope.row.useState == 1" type="success">使用</el-tag>
          <el-tag v-if="scope.row.useState == 2" type="danger">拆除</el-tag>
          <!-- <el-tag v-if="scope.row.deviceState == 2" type="danger">损坏</el-tag> -->
        </template>
        </el-table-column>
        <el-table-column
            prop="equipNo"
            align="center"
            show-overflow-tooltip
            label="设备编号"
          >
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
            prop="actNo"
            align="center"
            label="现场编号">
        </el-table-column>
        <el-table-column
            prop="projName"
            align="center"
            show-overflow-tooltip
            label="所属项目">
        </el-table-column>
     
        <el-table-column
            prop="equipType"
            align="center"
            label="设备类型">
            <template slot-scope="scope">
          <span v-if="scope.row.equipType == 0">塔吊</span>
          <span v-if="scope.row.equipType == 1">升降机</span>
          <span v-if="scope.row.equipType == 2">龙门吊</span>
          <span v-if="scope.row.equipType == 3">架桥机</span>
          <span v-if="scope.row.equipType == 4">爬模</span>
          <span v-if="scope.row.equipType == 5">挂篮</span>
          <span v-if="scope.row.equipType == 8">扬尘</span>
          <span v-if="scope.row.equipType == 9">喷淋</span>
        </template>
        </el-table-column>
        <el-table-column
            prop="equipModel"
            align="center"
            show-overflow-tooltip
            label="规格型号">
        </el-table-column>
        <el-table-column
            prop="equipDate"
            show-overflow-tooltip
            align="center"
            label="出厂日期">
        </el-table-column>
        <el-table-column
            prop="busiName"
            show-overflow-tooltip
            align="center"
            label="所属商户">
        </el-table-column>
        <el-table-column label="操作" align="center" >
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-edit"></i>
            </span>
            <!-- <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="iconfont icon-shangchuandaochu"></i>
            </span> -->
            </template>
          </el-table-column>
      </custom-tables>
  
      <equipmentArchivesEdit  :visible.sync="showDialog" @close="colseDialog" :title="title" @done="doneDialog" :item="item" :equipNoShow="equipNoShow">
    
      </equipmentArchivesEdit>
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import equipmentArchivesEdit from "../../../components/basic/equipmentArchivesEdit.vue";
  import basic from '../../../api/modules/basic'
  import {equipType,useState} from "../../../config/dataStatus"
  export default {
   
    components: {equipmentArchivesEdit, CustomTables, SearchHead},
    data() {
      return {
        tableData: [
        {address: '1', date: '2016-05-02', name: '王小虎', address: '上海市普陀区'}
        ],
        title:'',
        searchForm:{},
        options:[],
        total:0,
        showDialog: false,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      item:{},
      useStateList:useState,
      projectList:[],
      equipNoShow:false
      }
    },
    mounted() {
      this.getList()
      this.getProjectList()
    },
    methods:{
      getList() {
        basic.getBaseEquipListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    getProjectList(){
      basic.GetProjectList().then(res=>{
        console.log('项目列表',res);
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
      addFrom(){
        this.showDialog = true
        this.title='新增设备'
        this.item={}
        this.equipNoShow=false
      },
      colseDialog(){
        this.showDialog = false
        this.getList();
      },
      doneDialog(){
        this.showDialog = false
        this.getList()
      },
      submit(){
        this.showDialog = false
      },
      detailItem(item){
        //详情
        this.showDialog = true
        this.title='编辑设备'
        this.item=item 
        this.equipNoShow=true
    },
    deleteItem(){

    },
    handleSelectionChange(val){
        console.log(val);
        
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  
    }
  }
  </script>
  
  <style scoped lang="less">
  .detail{
    color: #008f4d;
}
.delete{
    margin-left: 10px;
}
.addBtn{
    background-color:#008f4d;
}
  </style>